.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  text-align: center;
  filter: drop-shadow(0px 0px 35px rgba(255, 255, 255, 0.6));
}
